import { SITE_CONFIG } from '@config/siteConfig';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useUserActiveFeatures } from '@domains/shared/hooks/useUserFeatures/useUserActiveFeatures';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { USER_TYPE } from '@lib/pages/user';
import { localiseRoute } from '@lib/routes/localiseRoute';
import { Button } from '@nexus/lib-react/dist/core/Button';
import type { ButtonVariants } from '@nexus/lib-react/dist/core/Button/Button';
import { USER_ACTIVE_FEATURE } from '@type/user/userActiveFeature';
import { useRouter } from 'next/router';
import type { FC } from 'react';

import { HiddenContainer } from './NexusAddItemButton.theme';

interface Props {
    dataCy?: string;
    investmentId?: string;
    onClick?: () => void;
    variant?: keyof typeof ButtonVariants;
    shouldShowPlusIcon?: boolean;
}

const { addNewUnit } = SITE_CONFIG.atlasRedirects;

export const NexusAddItemButton: FC<Props> = ({
    dataCy = 'add-item-button',
    investmentId,
    onClick,
    variant = 'secondary',
}) => {
    const { lang } = useSiteSettings();
    const router = useRouter();
    const [t] = useTranslations();
    const { user, isAwaitingUserData } = useLazyUser();
    let href = localiseRoute(`/${lang}/newAd/category`);
    let label = 'frontend.shared.add-item-button.default-label';

    const { userActiveFeatures, isFetchingActiveFeatures } = useUserActiveFeatures(user?.id);
    const shouldHideButton = isAwaitingUserData || isFetchingActiveFeatures;

    if (userActiveFeatures.includes(USER_ACTIVE_FEATURE.businessMember)) {
        return null;
    }

    if (user?.userType === USER_TYPE.developer) {
        if (investmentId) {
            href = addNewUnit.replace('{parentId}', investmentId);
        } else {
            label = 'frontend.shared.add-item-button.investment-label';
        }
    }

    const onButtonClick = (): void => {
        onClick?.();

        if (user && user.isSuspended) {
            const { userType } = user;
            const isBusinesUser = userType === USER_TYPE.agency || userType === USER_TYPE.developer;

            const paymentsPageHref = isBusinesUser
                ? localiseRoute(`/${lang}/myaccount/business/payments`)
                : localiseRoute(`/${lang}/myaccount/payments/invoices`);

            router.push(paymentsPageHref);

            return;
        }

        window.location.replace(href);
    };

    return (
        <HiddenContainer isHidden={shouldHideButton}>
            <Button data-cy={dataCy} onClick={onButtonClick} variant={variant} disabled={shouldHideButton}>
                {t(label)}
            </Button>
        </HiddenContainer>
    );
};
